import { actionTypes as sourceActionTypes } from 'actions/sources';
import { actionTypes as userActionTypes } from 'actions/user';
import { actionTypes } from 'actions/validation';
import { getLegacyOntologyFormat } from 'reducers/entities';

import produce from 'immer';

const getInitialState = () => ({
  reviews: [],
  offset: 0,
  current_review_id: undefined,
  // Ontology classification state for the current review
  ontologyToValidation: {},
  // Texts status state for the current review
  reviewTextsStatus: [],
  ontologies: undefined,
  sources: [],
  textsStatusList: [],
  productReferential: {
    sources: [],
    brands: [],
    products: [],
    generations: [],
  },
  reviewFilters: {
    sourceIds: [],
    brandId: '',
    productId: '',
    generationId: '',
    ontologyName: '',
    conceptId: '',
    texts: '',
  },
});

export function getCurrentReview(state) {
  return state.reviews.find((r) => r.id === state.current_review_id);
}

export function getDropdownItems(items) {
  return items.map((item) => ({
    key: item.id,
    value: item.id,
    text: item.name,
  }));
}

/**
 *
 * @param ontologyToValidation
 * @param ontology
 * @param chunkIndex
 * @return {Array} As expected by the semantic dropdown component.
 */
export function getExtraConceptsDropdown(
  { concepts },
  ontologyToValidation,
  ontologyName,
  chunkIndex
) {
  const ontologyDropdown = [];
  concepts.forEach((concept) => {
    const { predictionApproval, extraConceptIds } =
      ontologyToValidation[ontologyName][chunkIndex];

    // A concept is disabled if we added it or if it's in the list predicted by the classifier.
    const disabled =
      extraConceptIds.indexOf(concept.id) !== -1 ||
      concept.id in predictionApproval;

    ontologyDropdown.push({
      key: concept.id,
      value: concept.id,
      text: concept.fullName,
      disabled,
    });
  });
  return ontologyDropdown;
}

/**
 * Select classifications to be validated:
 * - if review was previously validated, use `validated_classifications`;
 * - if not, use `classifications`;
 *
 * @export
 * @param {*} reviews
 * @returns
 */
export function selectClassifications(review) {
  return review.validated_classifications &&
    Object.keys(review.validated_classifications).length
    ? review.validated_classifications
    : review.classifications;
}

/**
 * Return id of a non-disabled review (that we have not yet validated).
 * undefined if all reviews are disabled.
 */
function getNextReviewId(reviews, currentReviewId) {
  const remainingReviews = reviews.filter((r) => r.id !== currentReviewId);
  if (remainingReviews.length === 0) {
    // eslint-disable-next-line no-console
    console.error('No more non-disabled, non-validated reviews!');
  }
  return remainingReviews.length > 0 ? remainingReviews[0].id : undefined;
}

/**
 * We want the return object to have the same keys (ontologies) as the review classifications
 * @param review
 * :return: An object with:
 *    key: Ontology name
 *    value: List (each item describes a review chunk) of objects with the shape:
 *      { predictionApproval: {<concept id>: <bool>}, extraConceptIds: [<concept id>] }
 */
function getOntologyToValidation(review) {
  const ontologyToApproval = {};
  Object.keys(selectClassifications(review)).forEach((ont) => {
    const classifications = selectClassifications(review);
    ontologyToApproval[ont] = classifications[ont].items_texts.map(
      (chunkPredictions) =>
        // At this point we have the predictions for a given text chunk and all concepts.
        // We want to return an object mapping a concept id to the approval status (default to true)
        ({
          predictionApproval: chunkPredictions.reduce((obj, p) => {
            obj[p.db_concept.id] = true;
            return obj;
          }, {}),
          extraConceptIds: [],
        })
    );
  });
  return ontologyToApproval;
}

function getReviewTextsStatus(review) {
  // Select validated text status if it exists
  const textStatus =
    review.validated_texts_status_classifications &&
    review.validated_texts_status_classifications.length
      ? review.validated_texts_status_classifications
      : review.texts_status_classifications;
  return textStatus.map(
    (statusClassification) => statusClassification.status.id
  );
}

/**
 * Modifies `draft` inplace.
 */
function setNextReview(draft) {
  draft.current_review_id = getNextReviewId(
    draft.reviews,
    draft.current_review_id
  );
  const currentReview = draft.reviews.find(
    (r) => r.id === draft.current_review_id
  );
  draft.ontologyToValidation = currentReview
    ? getOntologyToValidation(currentReview)
    : undefined;
  draft.reviewTextsStatus = currentReview
    ? getReviewTextsStatus(currentReview, draft.textsStatusList)
    : undefined;
}
/**
 * Modifies `draft` inplace.
 */
function updateStateAfterReviewUpdate(draft, updatedReview) {
  draft.reviews = draft.reviews.filter((r) => r.id !== updatedReview.id);
  setNextReview(draft);
}

/**
 * @param idToConcept: object mapping id to concepts (objects with a parent field with parent id).
 * We transform the concept objects inplace to add a parentIds attribute with the list of ancestors.
 */
function augmentWithParentIds(idToConcept) {
  function getParentIds(conceptId) {
    const concept = idToConcept[conceptId];
    if (concept.parent === null || concept.parent === undefined) {
      return [];
    }
    return [...getParentIds(concept.parent?.id || null), concept.parent.id];
  }

  function getNodeFullName(conceptId) {
    const concept = idToConcept[conceptId];
    if (concept.parent === null || concept.parent === undefined) {
      return concept.name;
    }
    return `${getNodeFullName(concept.parent?.id)} > ${concept.name}`;
  }

  Object.entries(idToConcept).forEach(([id, concept]) => {
    concept.parentIds = getParentIds(id);
    concept.fullName = getNodeFullName(id);
  });
  return idToConcept;
}

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.RESET_REVIEWS:
      draft.reviews = [];
      draft.current_review_id = undefined;
      draft.offset = 0;
      break;
    case actionTypes.FETCH_REVIEWS_SUCCESS: {
      // Place the queried reviews first so they appear directly in the validation interface
      draft.reviews = [...action.reviews, ...draft.reviews];
      // NB: we don't update the offset as the already validated reviews will not appear anymore
      // in the query output
      draft.isLoading = false;
      setNextReview(draft);
      break;
    }
    case actionTypes.FETCH_ONTOLOGIES_SUCCESS:
      draft.ontologies = {};
      Object.values(getLegacyOntologyFormat(action.data)[0]).forEach(
        (idToConcept) => {
          augmentWithParentIds(idToConcept);
        }
      );
      draft.ontologies = action.data;
      break;
    case sourceActionTypes.FETCH_SOURCES_SUCCESS:
      draft.sources = action.data;
      break;
    case actionTypes.FETCH_BRANDS_SUCCESS:
      draft.productReferential.brands = action.data;
      draft.productReferential.products = [];
      draft.productReferential.generations = [];
      break;
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      draft.productReferential.products = action.data;
      draft.productReferential.generations = [];
      break;
    case actionTypes.FETCH_GENERATIONS_SUCCESS:
      draft.productReferential.generations = action.data;
      break;
    case actionTypes.FETCH_TEXTS_STATUS_SUCCESS:
      draft.textsStatusList = action.values;
      break;
    case actionTypes.UPDATE_REVIEW_DISABLED_SUCCESS:
      updateStateAfterReviewUpdate(draft, action.data);
      break;
    case actionTypes.UPDATE_REVIEW_SKIP:
      // Add 1 to offset to avoid this review in the next batch
      draft.offset += 1;
      draft.reviews = draft.reviews.filter(
        (review) => review.id !== action.reviewId
      );
      setNextReview(draft);
      break;
    case actionTypes.VALIDATE_REVIEW_FAILURE:
      break;
    case actionTypes.VALIDATE_REVIEW_SUCCESS:
      updateStateAfterReviewUpdate(draft, action.data);
      break;
    case actionTypes.UPDATE_PREDICTION_APPROVAL: {
      const { ontology, chunkIndex, conceptId, value } = action;
      draft.ontologyToValidation[ontology][chunkIndex].predictionApproval[
        conceptId
      ] = value;
      break;
    }
    case actionTypes.UNFOLD_PREDICTION_APPROVAL: {
      // Uncheck all concepts for an ontology and a review chunk
      const { ontology, chunkIndex } = action;
      const approvals =
        draft.ontologyToValidation[ontology][chunkIndex].predictionApproval;
      Object.keys(approvals).forEach((conceptId) => {
        approvals[conceptId] = false;
      });
      break;
    }
    case actionTypes.SET_EXTRA_CONCEPT: {
      const { ontology, chunkIndex, value } = action;
      // value is the value after adding the element to the selection.
      // We add all parents of the concept if not already here or in the machine-predicted list.
      const draftChunkValidation =
        draft.ontologyToValidation[ontology][chunkIndex];
      if (value.length === 0) {
        draftChunkValidation.extraConceptIds = [];
        break;
      }
      const selectedOntology = draft.ontologies.find(
        ({ name }) => name === ontology
      );
      const concepts = selectedOntology.concepts.filter((concept) =>
        value.includes(concept.id)
      );
      const machinePredictedIds = Object.keys(
        draftChunkValidation.predictionApproval
      );
      // We remove the last value only to reinsert it later. This gives us parent -> child ordering.
      const conceptId = value.pop();

      // We should only ever act on the last item but it does not hurt to check.
      for (const concept of concepts) {
        // Add parents to selection
        for (const parentId of concept.parentIds) {
          if (
            value.indexOf(parentId) === -1 &&
            machinePredictedIds.indexOf(parentId) === -1
          ) {
            value.push(parentId);
          }
        }
      }

      value.push(conceptId);
      draftChunkValidation.extraConceptIds = value;
      break;
    }
    case actionTypes.SET_TEXTS_STATUS: {
      const { chunkIndex, value } = action;
      draft.reviewTextsStatus[chunkIndex] = value;
      break;
    }
    case actionTypes.SET_SOURCE_FILTER: {
      const { sourceIds } = action;
      // sourceIds contain all the selected ids.
      draft.reviewFilters.sourceIds = sourceIds;
      break;
    }
    case actionTypes.SET_BRAND_FILTER: {
      const { brandId } = action;
      draft.reviewFilters.brandId = brandId;
      if (!brandId) {
        draft.productReferential.products = [];
        draft.productReferential.generations = [];
      }
      draft.reviewFilters.productId = '';
      draft.reviewFilters.generationId = '';
      break;
    }
    case actionTypes.SET_PRODUCT_FILTER: {
      const { productId } = action;
      draft.reviewFilters.productId = productId;
      if (!productId) {
        draft.productReferential.generations = [];
      }
      draft.reviewFilters.generationId = '';
      break;
    }
    case actionTypes.SET_GENERATION_FILTER: {
      const { generationId } = action;
      draft.reviewFilters.generationId = generationId;
      break;
    }
    case actionTypes.SET_CONCEPT_FILTER: {
      draft.reviewFilters.ontologyName = action.ontologyName;
      draft.reviewFilters.conceptId = action.conceptId;
      break;
    }
    case actionTypes.SET_TEXTS_FILTER: {
      draft.reviewFilters.texts = action.value;
      break;
    }
    case actionTypes.SET_REVIEW_IDS_FILTER: {
      draft.reviewFilters.reviewIds = action.value;
      break;
    }
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getInitialState();
    default:
      break;
  }
  return draft;
}, getInitialState());

export const textsStatusListSelector = (state) =>
  state.validation.textsStatusList;
export const reviewTextsStatusSelector = (state) =>
  state.validation.reviewTextsStatus;
